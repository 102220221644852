import Vue from "vue";
import Vuetify from "vuetify/lib";
// import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify);

export default new Vuetify({
  breakpoint: {
    mobileBreakpoint: 768,
  },
  theme: {
    themes: {
      light: {
        primary: "#77d1ad",
        accent: "#268fff",

        // 独自項目は他のクラス名とぶつからないようにプレフィックス(gc-)をつける
        // 一番後ろの地の色
        // ここを変更する場合はglobal.scssのnormal-background・Home.vueと統一すること
        "gc-background": "#82dcb8",

        // 実質的な背景色
        "gc-ground": "#ffffff",

        // ヘッダー項目の背景色
        "gc-header": "#ffffff",

        // サーチバーの背景色
        "gc-searchbar": "#eeeeee",

        // 画像以外の添付ファイルの背景色
        "gc-uploadfile": "#eeeeff",

        // 区切り線の色
        // Home.vueのv-bottom-navigationのボーダー色とも合わせたほうが良い
        "gc-divider": "#f0f0f0",

        // 設定画面などの帯の色
        "gc-band": "#d7f5e9",

        // 相手のチャットの背景色
        "gc-opponent-chat": "#e8e8e8",

        // 自分のチャットの背景色
        "gc-self-chat": "#c7edde",

        // 店舗や患者などの選択色
        // hover色はContact.vue, Chat.vueなどで
        "gc-selected": "#c0eddb",

        // ボタン色
        "gc-button": "#cbf2e2",

        // テキスト項目はプレフィックスを(gct-)とする
        // 普通の文字色
        "gct-normal": "#484848",

        // primaryの文字色版というイメージ
        "gct-primary": "#064f32",

        // ボタン文字色
        "gct-button": "#0a5c3a",

        // 画像以外の添付ファイルの文字色
        "gct-file": "#303060",

        // 淡い文字色
        "gct-light": "#808080",

        // すごく淡い文字色
        "gct-pale": "#c8c8c8",

        // dialog内の淡い文字色
        "gct-dialog-light": "#dfdfdf",

        // 白文字色
        "gct-white": "#ffffff",

        // dialog内の背景色
        "gc-dialog": "#9f9fa0",

        // 灰色ボタン色
        "gc-button-gray": "#aaaaaa",

        // 灰色ヘッダー色
        "gc-header-gray": "#8c8c8c",

        // コントロールの背景色
        "gc-controller": "#e8e8e8",
      },
    },
  },
});
