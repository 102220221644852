<template>
  <v-dialog v-model="isDisplay" persistent :width="dialogWidth" :max-width="maxWidth" dark>
    <v-card class="text-left">
      <v-card-title class="gct-normal--text">{{ title }}</v-card-title>
      <v-card-text class="subtitle-1">
        <slot></slot>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <simple-button v-if="isCancel" @click="onCancelClicked"> {{ cancelButtonText }} </simple-button>
        <simple-button v-if="isDelete" @click="onDeleteClicked"> {{ deleteButtonText }} </simple-button>
        <slot name="buttons"></slot>
        <simple-button @click="onOkClicked" :disabled="!isValid"> {{ okButtonText }} </simple-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import SimpleButton from "@/components/SimpleButton.vue";

@Component({ components: { SimpleButton } })
export default class EditDialog extends Vue {
  @Prop({ default: "" })
  title!: string;

  @Prop({ default: true })
  isValid!: boolean;

  @Prop({ default: "" })
  okButtonText!: string;

  @Prop({ default: "" })
  cancelButtonText!: string;

  @Prop({ default: "" })
  deleteButtonText!: string;

  @Prop({ default: false })
  isDelete!: boolean;

  @Prop({ default: false })
  isDisplay!: boolean;

  @Prop({ default: "800px" })
  maxWidth!: string;

  @Prop({ default: true })
  isCancel!: boolean;

  get dialogWidth() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return "50%";
    }

    if (this.$vuetify.breakpoint.mdOnly) {
      return "60%";
    }

    if (this.$vuetify.breakpoint.smOnly) {
      return "80%";
    }

    return "auto";
  }

  onCancelClicked() {
    this.$emit("cancelClicked");
  }

  onOkClicked() {
    this.$emit("okClicked");
  }

  onDeleteClicked() {
    this.$emit("deleteClicked");
  }
}
</script>

<style lang="scss" scoped>
.v-dialog div {
  background-color: #9f9fa0 !important;
  color: #ffffff !important;
}
</style>
