import { userStore } from "@/store/UserStore";
import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import store from "@/store";
import { db, firebase } from "@/common/firebase";

interface Members {
  [id: string]: MemberData;
}

interface MemberData {
  pdsId: string;
}

@Module({ dynamic: true, store, name: "memberStore" })
class MemberStore extends VuexModule {
  members_ms: Members = {};

  snapshotUnsubscriber_ms: (() => void) | null = null;

  @Action({ rawError: true })
  async initMembers_ms(): Promise<void> {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_ms) return;
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: memberStore");

    const userCode = userStore.currentUser_us.userCode;
    const selfId = userStore.currentUser_us.id;

    try {
      // 初回取得
      const doc = await db.doc(`credentials/${selfId}`).get();
      if (doc.exists) {
        const data = doc.data();
        if (data && !data.empty) {
          const member: MemberData = { pdsId: data.pdsId };
          self.setMember_ms({ userId: selfId, member });
        }
      }

      const baseQuery = db.collection(`credentials`).where("parentId", "==", userCode);

      let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
      const snapshots = (await baseQuery.get()).docs;
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const data = snapshot.data();
        const userId = snapshot.id;
        const member: MemberData = { pdsId: data.pdsId };

        self.setMember_ms({ userId, member });

        if (maxUpdatedAt === null || data.updatedAt > maxUpdatedAt) {
          maxUpdatedAt = data.updatedAt;
        }
      }

      // 変更監視
      const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

      self.setSnapshotUnsubscriber_ms({
        snapshotUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (x) {
            const data = x.doc.data();
            const userId = x.doc.id;
            const member: MemberData = { pdsId: data.pdsId };

            if (x.type === "added") {
              self.setMember_ms({ userId, member });
            } else if (x.type === "modified") {
              self.setMember_ms({ userId, member });
            } else if (x.type === "removed") {
              self.unsetMember_ms({ userId });
            }
          });
        }),
      });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }
  }

  @Action({ rawError: true })
  destroy_ms() {
    if (this.snapshotUnsubscriber_ms) {
      this.snapshotUnsubscriber_ms();
    }

    this.setSnapshotUnsubscriber_ms({ snapshotUnsubscriber: null });
    this.setMembers_ms({ members: {} });
  }

  @Mutation
  private setMembers_ms(p: { members: Members }) {
    this.members_ms = p.members;
  }

  @Mutation
  private setMember_ms(p: { userId: string; member: MemberData }) {
    this.members_ms[p.userId] = p.member;
  }

  @Mutation
  private unsetMember_ms(p: { userId: string }) {
    delete this.members_ms[p.userId];
  }

  @Mutation
  private setSnapshotUnsubscriber_ms(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_ms = p.snapshotUnsubscriber;
  }
}

export const memberStore = getModule(MemberStore);
