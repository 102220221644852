import axios from "axios";
import * as util from "@/common/util";
import { functionOrigin } from "@/common/firebase";
import { userStore } from "@/store/UserStore";
import { authStore } from "@/store/AuthStore";
import { SendMessageCallInput, SendMessageCallOutput, SendMessageResult } from "@/shared/call/SendMessageCallData";
import { MessageType } from "@/shared/model/ChatMessageModel";
import { ResponseResult } from "@/shared/model/ResponseResult";
import { ReserveRequestInput, ReserveRequestOutput } from "@/shared/request/ReserveRequestData";

export async function deliveryNow(deliverydocId: string) {
  const selfId = userStore.currentUser_us?.id ?? "";

  const deliveryRequestInput: ReserveRequestInput = {
    userId: selfId,
    reserveId: deliverydocId,
  };

  const params = JSON.stringify(deliveryRequestInput);
  const authToken = await authStore.currentAuth_as2?.getIdToken();
  try {
    const reserveResult = await axios.post<ReserveRequestOutput>(
      functionOrigin + "sendReserveRequest_gR9FPcBJ",
      params,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + authToken,
        },
      }
    );

    // OK以外は403例外が発生するので、ここは通らないけど一応
    if (reserveResult.data.resultType !== "OK") {
      await util.showDialog({ title: "エラー", dialogText: "メッセージ送信に失敗しました" });
      return false;
    }

    return reserveResult.data;
  } catch (e) {
    // 403は例外発生扱いであることに注意
    const res = e as any;
    const reserveResult = res?.response?.data as ReserveRequestOutput;

    if (!reserveResult) {
      throw res;
    }

    switch (reserveResult.resultType) {
      case "OK":
      case "Failed": {
        await util.showDialog({ title: "エラー", dialogText: "配信データの送信に失敗しました" });
        return false;
      }

      case "InvalidFileSizePerDay": {
        await util.showDialog({ title: "エラー", dialogText: "一日にアップロードできる上限を超えました" });
        return reserveResult;
      }

      case "InvalidSend": {
        await util.showDialog({ title: "エラー", dialogText: "配信データの送信に失敗しました" });
        return reserveResult;
      }

      default: {
        throw res;
      }
    }
  }
}

interface SendMessageInput {
  senderYakkyokuCode: string;
  destYakkyokuCode: string;
  messageBody: string;
  messageType?: MessageType;
  loginId: string;
}

type SendMessageRequestResult = "OK" | "Failed";

interface SendMessageRequestOutput {
  resultType: SendMessageRequestResult;
  error: ResponseResult;
}

// HTTP リクエストでメッセージ送信（通知や自動応答でのメッセージ送信用）
export async function sendMessage(
  senderId: string,
  destId: string,
  sendMessage: string,
  messageType?: MessageType
): Promise<boolean> {
  const loginId = localStorage.getItem("loginId") ?? "";
  const sendMessageRequestInput: SendMessageInput = {
    senderYakkyokuCode: senderId,
    destYakkyokuCode: destId,
    messageBody: sendMessage,
    messageType: messageType,
    loginId: loginId,
  };
  if (!senderId || !destId || !sendMessage) return false;

  const params = JSON.stringify(sendMessageRequestInput);
  const authToken = await authStore.currentAuth_as2?.getIdToken();

  try {
    const result = await axios.post<SendMessageRequestOutput>(functionOrigin + "sendMessage_hK7F4V63", params, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + authToken,
      },
    });

    return result.data.resultType === "OK";
  } catch (e) {
    // throw はしない
    return false;
  }
}

// ChatMessage.vue 子コンポーネントからのメッセージ送信（onCall 経由）
export async function sendMessageInside(
  messageBody: string,
  opponentId: string,
  messageType?: MessageType
): Promise<SendMessageResult> {
  const selfId = userStore.currentUser_us?.id;
  if (!selfId) {
    throw new Error("ユーザーデータ不正 sendMessage");
  }

  const sendMessageResult = await util.firebaseCall<SendMessageCallInput, SendMessageCallOutput>("sendMessageCall", {
    senderId: selfId,
    destId: opponentId,
    messageBody: messageBody,
    files: [],
    messageType: messageType,
    loginId: util.getLoginId(),
  });

  return sendMessageResult.resultType;
}
