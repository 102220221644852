<template>
  <v-avatar :color="imageUri ? 'transparent' : color" :size="size">
    <img v-if="imageUri" :src="imageUri" />
    <span v-else :style="{ color: textColor }">{{ text }}</span></v-avatar
  >
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class Avatar extends Vue {
  @Prop({ required: true })
  size!: number;

  @Prop({ default: "" })
  imageUri!: string;

  @Prop({ default: "" })
  text!: string;

  @Prop({ default: "" })
  color!: string;

  @Prop({ default: "" })
  textColor!: string;
}
</script>

<style lang="scss" scoped></style>
