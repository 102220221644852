<template>
  <v-tooltip top open-delay="300" close-delay="0" content-class="tooltip-style" :disabled="!tooltipText">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        :class="isPc ? 'mx-2' : 'mx-1'"
        color="#FFF"
        :small="isPc"
        :x-small="!isPc"
        height="33px"
        elevation="0"
        v-bind="attrs"
        v-on="on"
        @click="onClicked"
      >
        <slot></slot>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class IconButton extends Vue {
  @Prop({ default: true })
  isPc!: boolean;
  @Prop({ default: "" })
  tooltipText!: string;

  onClicked() {
    this.$emit("click");
  }
}
</script>

<style lang="scss" scoped>
.tooltip-style {
  font-size: 12px;
  padding: 0px 6px;
}
</style>
