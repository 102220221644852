import { ChatMessageModel, MessageFileModel, MessageType } from "@/shared/model/ChatMessageModel";
import { ChatType } from "@/shared/model/ChatModel";
import { ReadSignModel } from "@/shared/model/ReadSignModel";
import firebase from "firebase/compat/app";
import { FirestoreModel } from "../FirestoreModel";
import * as logic from "@/common/logic";

export type SendingType = "complete" | "sending" | "failed";

// メッセージに関してはほとんど差がないため、個別チャットとルームチャットでクラスを分けない
export class ChatMessageData implements ChatMessageModel, FirestoreModel {
  // model
  id: string;
  senderId: string;
  messageBody: string;
  files: MessageFileModel[];
  enabled: boolean;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
  readSign: ReadSignModel | null = null;
  loginId?: string;
  messageType?: MessageType;

  // 独自
  chatId: string;
  chatType: ChatType;
  isSelf: boolean;
  isOldestUnread: boolean;
  sendingType: SendingType = "complete";
  selfId: string;
  name?: string;

  constructor(id: string, selfId: string, chatId: string, chatType: ChatType, model: ChatMessageModel) {
    this.id = id;
    this.senderId = model.senderId;
    this.messageBody = model.messageBody;
    this.files = model.files;
    this.enabled = model.enabled;

    this.createdAt = model.createdAt;
    this.updatedAt = model.updatedAt;
    this.loginId = model.loginId;
    this.messageType = model.messageType;

    this.chatId = chatId;
    this.chatType = chatType;
    this.isSelf = selfId === model.senderId;
    this.isOldestUnread = false;
    this.selfId = selfId;
  }

  readCount() {
    return Number(this.readSign?.readCount) || 0;
  }

  supportJudgementOpponentId() {
    const chatIds = this.chatId.substring(this.chatId.indexOf("_") + 1).split("-");
    const opponentId = chatIds.filter((id) => id !== this.selfId);
    if (opponentId.length > 0) {
      const userdata = logic.getUserDataAsMaster(opponentId[0]);
      const isOroshi = logic.getUserIsOroshiGroup(opponentId[0]);
      if (userdata) {
        // 相手ユーザーがサポートもしくは卸グループの場合
        // サポート扱いにする（未既読は見せない）
        return userdata.isSupport || isOroshi;
      }
    }
    return false;
  }

  readCountString() {
    if (!this.isSelf) {
      return "";
    }

    if (this.messageType && this.messageType === "notification") {
      return "";
    }

    if (this.sendingType === "sending") {
      return "送信中";
    }

    if (this.sendingType === "failed") {
      return "送信失敗";
    }

    if (this.supportJudgementOpponentId()) {
      return "";
    }

    if (this.readCount() <= 0) {
      return "未読";
    }

    if (this.chatType === "Individual") {
      return "既読";
    } else {
      return "既読 " + this.readCount();
    }
  }

  isUnread() {
    return !this.isSelf && (this.readSign?.readCount || 0) === 0;
  }
}
