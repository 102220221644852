<template>
  <!-- rippleをONにしないとmobile safariで表示が乱れるので注意 -->
  <v-switch
    :input-value="inputValue"
    @change="onChange"
    class="ma-0 pa-0"
    :disabled="disabled"
    :hide-details="true"
    :ripple="false"
    inset
    :label="label"
  ></v-switch>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class SimpleSwitch extends Vue {
  @Prop({ default: false })
  disabled!: boolean;

  @Prop({ default: false })
  inputValue!: boolean;

  @Prop({ default: undefined })
  label!: string;

  onChange(value: boolean) {
    this.$emit("change", value);
  }
}
</script>

<style lang="scss" scoped></style>
