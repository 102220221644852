<template>
  <v-app>
    <v-main>
      <keep-alive :include="cachedComponents">
        <router-view />
      </keep-alive>

      <v-overlay opacity="0.25" :value="isDisplayOverray">
        <v-progress-circular indeterminate size="50" width="4"></v-progress-circular>
      </v-overlay>

      <v-dialog v-model="isDisplayDialog" persistent :width="dialogWidth" max-width="800px">
        <v-card class="text-left">
          <v-card-title>{{ title }}</v-card-title>
          <v-card-text class="subtitle-1">
            <span v-html="dialogText"></span>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <simple-button v-if="hasCancel" @click="onCancelClicked"> Cancel </simple-button>
            <simple-button @click="onOkClicked"> OK </simple-button>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-main>
  </v-app>
</template>

<script lang="ts">
import firebase from "firebase/compat/app";
import { Component, Vue, Watch } from "vue-property-decorator";
import { auth, db, messaging } from "@/common/firebase";
import * as logic from "@/common/logic";
import * as util from "@/common/util";
import SimpleButton from "@/components/SimpleButton.vue";
import { appStore } from "@/store/AppStore";
import { authStore } from "@/store/AuthStore";
import { errorStore } from "@/store/ErrorStore";
import { localStore } from "@/store/LocalStore";
import { userStore } from "@/store/UserStore";
import { ErrorModel } from "@/shared/model/ErrorModel";
import { WebPushData } from "@/shared/push/WebPushData";
import { CustomLoginCallInput, CustomLoginCallOutput } from "./shared/call/CustomLoginCallData";

@Component({ components: { SimpleButton } })
export default class App extends Vue {
  // ダイアログ
  isDisplayDialog = false;
  title = "";
  dialogText = "";
  hasCancel = false;

  // オーバーレイ
  isDisplayOverray = false;

  // キャッシュ
  // 【※要注意】ここに登録する名前は明示的にコンポーネント名(router名ではない)を指定する必要あり
  // そうしないとdebugモードでは良いが、テスト/本番環境で正常動作しない(keep-aliveしない)
  // 参考：https://forum.vuejs.org/t/keep-alive-doesnt-work-in-production-mode/51496
  cachedComponents = ["Home", "ChatMessage", "Loading"];

  // ストア経由
  get isDisplayDialogStore() {
    return appStore.isDisplayDialog_as1;
  }

  get isDisplayOverrayStore() {
    return appStore.isDisplayOverray_as1;
  }

  get dialogWidth() {
    if (this.$vuetify.breakpoint.lgAndUp) {
      return "50%";
    }

    if (this.$vuetify.breakpoint.mdOnly) {
      return "60%";
    }

    if (this.$vuetify.breakpoint.smOnly) {
      return "80%";
    }

    return "auto";
  }

  get error() {
    return errorStore.error_es;
  }

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }

  get isPc() {
    return !this.isMobile;
  }

  onOkClicked() {
    appStore.endDialog_as1(true);
  }

  onCancelClicked() {
    appStore.endDialog_as1(false);
  }

  async customLogin(customTokenId?: string): Promise<CustomLoginCallOutput | null> {
    if (!customTokenId) return null;

    const callResult = await util.firebaseCall<CustomLoginCallInput, CustomLoginCallOutput>("customLoginCall", {
      customTokenId,
    });

    if (!callResult.errorMessage) {
      return callResult;
    } else {
      return null;
    }
  }

  @Watch("error")
  async onErrorOccurred(error: any) {
    try {
      // コンソールに表示されるためあえてここで表示しなくても良さそう
      // console.error(error);
      let errorMessage = "予期しないエラーが発生したため再読み込みします";
      let isNotificationError = false;
      if (error instanceof Error) {
        // revokeUsersで更新トークンはrevokeされたけどsingOut命令がうまく実行されなかった場合(ios端末でバックグラウンドにいた、など)
        if (
          error.name === "Error" &&
          error.message === "The user's credential is no longer valid. The user must sign in again."
        ) {
          errorMessage = "お手数ですがセキュリティのため再度ログインしてください";
        }

        // 通知非対応のブラウザではこういうエラーが発生するっぽい
        if (error.name === "AbortError" && error.message === "Registration failed - push service error") {
          isNotificationError = true;
          errorMessage = "通知機能に非対応のブラウザのため通知機能を使用しない設定で再読み込みします";
        }

        if (authStore.currentAuth_as2) {
          const uid = await logic.getAuthUserUid(authStore.currentAuth_as2);

          // サインイン状態ならfirestoreにエラーを保管
          // なんでもかんでも記録しておくと膨れ上がるのが怖いのでuid, メッセージごとに保管、一応回数も記録しておく
          const errorSnapshot = await db
            .collection("errors")
            .where("userId", "==", uid)
            .where("message", "==", error.message)
            .get();

          if (errorSnapshot.empty) {
            const errorModel: ErrorModel = {
              userId: uid,
              name: error.name,
              message: error.message,
              stack: error.stack || "",
              count: 1,
              updatedAt: util.now(),
            };

            // await しない
            db.collection("errors").doc().set(errorModel);
          } else {
            errorSnapshot.docs.forEach(async (doc: any) => {
              const errorModel: Partial<ErrorModel> = { count: firebase.firestore.FieldValue.increment(1) };
              // await しない
              doc.ref.set(errorModel, { merge: true });
            });
          }
        } else {
          // サインイン状態じゃないならしょうがないのでせめてエラー情報をダイアログに表示しておく
          // OKボタンが表示されなくなることがないように一応100文字で切っておく
          errorMessage += "<br>エラー名：" + error.name;
          errorMessage += "<br>エラーメッセージ：" + error.message.substring(0, 100);
        }
      }

      await util.showDialog({ title: "エラー", dialogText: errorMessage });

      if (isNotificationError) {
        window.location.href = window.location.href + "?disableNotification=true";
      } else {
        // reload(true)は非推奨(deprecated)だが、とりあえずおまじない程度に
        // キャッシュが原因でエラーが発生する場合はキャッシュ削除してもらうしかないかもしれない、以下参考
        // https://uzurea.net/cacheclear-and-superreload-2018/
        window.location.reload();
      }
    } catch {
      // エラー処理で発生したエラーは無視、無限ループにならないように
    }
  }

  @Watch("isDisplayDialogStore")
  onIsDisplayDialogChanged(newValue: boolean) {
    const option = appStore.dialogOption_as1;
    if (newValue && option) {
      this.title = option.title;
      this.dialogText = option.dialogText;
      this.hasCancel = option.hasCancel || false;
    }

    this.isDisplayDialog = newValue;
  }

  @Watch("isDisplayOverrayStore")
  onIsDisplayOverrayStoreChanged(newValue: boolean) {
    this.isDisplayOverray = newValue;
  }

  @Watch("isMobile")
  async onIsMobileChanged() {
    // VideoChatはリロードさせない
    const name = this.$route.name;
    if (name === "VideoChat") return;
    // await util.showDialog({ title: "お知らせ", dialogText: "画面の向きが変更されたため再読み込みします" });
    window.location.reload();
  }

  // Vueのライフサイクルメソッドはasynにできるがその場合、最初のawaitより先に他のライフサイクルメソッドが実行されてしまうことに要注意
  // なのでasyncメソッドの実行は後半にまとめた方が吉
  // 参考:https://forum.vuejs.org/t/setting-the-created-life-cycle-hook-to-async/88120
  async beforeCreate() {
    if (window.greenchat.destChatId) {
      localStore.setDestChatId_lcs({ destChatId: window.greenchat.destChatId });
    }

    if (window.greenchat.nativeType) {
      localStore.setNativeType_lcs({ nativeType: window.greenchat.nativeType });
    }

    // native event
    window.greenchat.nativeFcmTokenReceived = (fcmToken: string) => {
      localStore.setNativeFcmToken_lcs({ fcmToken });
    };

    window.greenchat.notificationClicked = (destChatId: string) => {
      localStore.setDestChatId_lcs({ destChatId });
    };

    window.greenchat.tryConnectDb = () => {
      // nativeアプリはバックグラウンドに回ると接続がOSに切断されてしまうため
      // 早期復帰を試みる、効果は不明
      db.doc("meta/void").get();
    };

    util.postMessageToNative("AppInited");

    if (window.greenchat.disableNotification === "true") {
      const swr = await navigator.serviceWorker.getRegistration(
        window.location.origin + "/firebase-cloud-messaging-push-scope"
      );

      if (swr) {
        await swr.unregister();
      }
    }
  }

  created() {
    // console.log("App.Vue created");

    localStore.loadLocalStorage_lcs();

    // スタイルの設定
    const isLightContent = this.isPc;
    util.postMessageToNative("UpdateUIColor", JSON.stringify({ isLightContent }));

    // カスタムログイン
    if (window.greenchat.customTokenId) {
      // customTokenIdが指定されているならそれでログインを試みる
      const customLoginPromise = new Promise<CustomLoginCallOutput | null>((resolve) => {
        this.customLogin(window.greenchat.customTokenId).then((customLoginCallOutput) => {
          resolve(customLoginCallOutput);
        });
      });

      authStore.setInitedCustomLogin_as2({ initedCustomLogin: customLoginPromise });
    }

    auth.onAuthStateChanged(async (currentAuth: any) => {
      // カスタムログインの状況取得
      let customLoginCallOutput: CustomLoginCallOutput | null = null;
      if (authStore.initedCustomLogin_as2) {
        customLoginCallOutput = await authStore.initedCustomLogin_as2;
      }

      if (currentAuth) {
        // すでにサインイン状態でも異なるuserIdによるカスタムログインがされてるならsignoutし、再度カスタムログイン
        if (customLoginCallOutput && customLoginCallOutput.userId !== currentAuth.uid) {
          await auth.signOut();
          return;
        }

        const uid = await logic.getAuthUserUid(currentAuth);

        authStore.setCurrentAuth_as2({ currentAuth });
        const userData = await logic.getCurrentUserData(uid);
        const userPrivateData = await logic.getCurrentUserPrivateData(uid);
        userStore.setCurrentUser_us({ currnetUser: userData });
        userStore.setCurrentUserPrivate_us({ currnetPrivateUser: userPrivateData });
        authStore.loadedAuth_as2();

        // ログインユーザーのデータ取得
        const loginUser = await logic.getCurrentUserLoginData(util.getLoginId());
        userStore.setCurrentUserLogin_us({ currentUserLogin: loginUser });

        // キャッシュ登録
        this.cachedComponents = ["Home", "ChatMessage", "Loading", "VideoChat"];
        if (localStore.destChatId_lcs || this.$route.name !== "VideoChat") {
          // 注意：ここでrepalceしていても結局NavigationGuard(beforeEach)を使用していると結局pushされてしまう
          await this.$router.replace({ path: "/home/chat", query: window.greenchat.inheritSearch });
        }

        // 終了処理
        authStore.setInitedCustomLogin_as2({ initedCustomLogin: null });
      } else {
        // カスタムログインに成功したならそれでログイン
        if (customLoginCallOutput?.customToken) {
          await auth.signInWithCustomToken(customLoginCallOutput.customToken);
          return;
        }

        authStore.loadedAuth_as2();
        logic.destroyStores();

        // キャッシュクリア
        this.cachedComponents = [];
        if (this.$route.name === "VideoChat") window.close();
        await this.$router.replace({ path: "/login", query: window.greenchat.inheritSearch });

        // 終了処理
        authStore.setInitedCustomLogin_as2({ initedCustomLogin: null });
      }
    });

    if (messaging) {
      // フォアグラウンド状態のメッセージ処理
      messaging.onMessage(async (payload: any) => {
        // console.log("メッセージ受信(foreground)", payload);
        const swr = await navigator.serviceWorker.getRegistration(
          window.location.origin + "/firebase-cloud-messaging-push-scope"
        );
        const data = payload.data as WebPushData;
        const option: NotificationOptions = {
          body: data.body,
          badge: "/GreenChatLogo.png",
          icon: "/GreenChatLogo.png",
          data: { chatId: data.chatId },
        };
        if (data.isCollapse === "true") {
          option.renotify = false;
          option.tag = "new_message";
        }

        swr?.showNotification(data.title, option);
      });

      // 通知自体をクリックしたときの処理(ここではstoreの状態を変更するだけであとは各画面のWatchなどで処理する)
      navigator.serviceWorker.addEventListener("message", async (event) => {
        // console.log(event.data);
        if (event.data.messageType === "notification-clicked") {
          localStore.setDestChatId_lcs({ destChatId: event.data.chatId });
        }
      });

      // 通知の許可設定を直接変更されたときに一応フラグを変更しておく
      if ("permissions" in navigator) {
        navigator.permissions.query({ name: "notifications" }).then(function (notificationPerm) {
          notificationPerm.onchange = async function () {
            if (notificationPerm.state !== "granted") {
              localStore.setIsNotify_lcs({ isNotify: false });
              localStore.saveLocalStorage_lcs();
            }
          };
        });
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   color: #2c3e50;
// }

// #nav {
//   padding: 30px;

//   a {
//     font-weight: bold;
//     color: #2c3e50;

//     &.router-link-exact-active {
//       color: #42b983;
//     }
//   }
// }

.v-dialog div,
.v-dialog div span {
  background-color: #9f9fa0;
  color: #ffffff;
}
</style>
