<template>
  <div v-show="isDisplay" class="opp-style" :class="{ 'align-center d-flex': videoFrameType, share: isShare }">
    <audio v-if="!videoFrameType" :id="'audio_' + member.userId" autoplay></audio>
    <avatar
      v-if="member.userIcon"
      :size="avatarSize"
      :imageUri="member.userIcon.imageUri"
      :text="member.userIcon.text"
      :color="member.userIcon.color"
      :textColor="member.userIcon.textColor"
      :style="{ 'font-size': avatarSize * 0.4 + 'px' }"
    ></avatar>

    <div
      v-if="viewDetails"
      :style="{ height: isShare ? '21px' : '26px', width: videoFrameType ? '100%' : '' }"
      class="d-flex align-end"
      :class="!!videoFrameType ? 'justify-space-between' : 'justify-center'"
    >
      <div
        :title="member.userName"
        class="text-name"
        :class="{ 'ml-1': !!videoFrameType }"
        :style="{
          'max-width': !!videoFrameType ? '130px' : textNameWidth + 'px',
          'font-size': !!videoFrameType ? '14px' : '',
        }"
      >
        {{ member.userName }}
      </div>
      <v-icon v-if="member.muted" class="material-icons-round mic" :class="{ share: isShare }">mic_off</v-icon>
      <v-icon v-else class="material-icons-round mic" :class="{ 'mic-active': member.isSpeak, share: isShare }"
        >mic</v-icon
      >
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import Avatar from "@/components/Avatar.vue";
import { UserIcon, Member } from "@/views/VideoChat.vue";

@Component({ components: { Avatar } })
export default class VideoChatFrame extends Vue {
  @Prop({})
  avatarSize!: number;

  @Prop({})
  member!: Member;

  @Prop({ default: true })
  isDisplay!: boolean;

  @Prop({ default: "" })
  videoFrameType!: string;

  @Prop({ default: false })
  isShare!: boolean;

  userIcon: UserIcon = { imageUri: "", text: "", color: "", textColor: "" };
  userName = "";
  userId = "";
  stream: MediaStream | null = null;

  get textNameWidth() {
    if (this.isShare) {
      return 130;
    }
    return this.avatarSize;
  }

  get viewDetails() {
    return this.videoFrameType === "";
  }

  setUserData() {
    if (!this.member.stream) return;
    let changeFlg = false;

    if (this.videoFrameType !== "") return;

    // audio設定
    const audio = document.getElementById(`audio_${this.member.userId}`) as HTMLAudioElement;
    if (!audio) return;
    if (audio.srcObject !== this.member.stream) {
      changeFlg = true;
    }

    if (changeFlg) {
      audio.srcObject = this.member.stream;
      audio.autoplay = true;
    }
  }

  mounted() {
    this.setUserData();
  }

  updated() {
    this.setUserData();
  }

  beforeDestroy() {
    const audio = document.getElementById(`audio_${this.member.userId}`);
    if (audio && this.stream) {
      this.stream.getTracks().forEach((track) => track.stop());
    }
    this.stream = null;
    this.userIcon = { imageUri: "", text: "", color: "", textColor: "" };
    this.userName = "";
    this.userId = "";
  }
}
</script>
<style lang="scss">
.opp-video {
  height: auto;
  box-sizing: border-box;
}
video:focus {
  border: solid 1px #82dcb8;
}
.opp-style {
  display: inline-block;
  margin: 5px;
}

.text-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.opp-style.share {
  font-size: 14px;
  min-width: 180px;
  max-width: 180px;
  height: 128px;
  display: grid;
  grid-template-rows: 1fr 21px;
  align-items: center;
  justify-items: center;
}

.noVideo .opp-style.share {
  height: 80px;
}

.opp-style.share:hover {
  cursor: pointer;
  color: #77d1ad;
}

.mic-active {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #c0eddb;
  box-shadow: 0 0 20px #c0eddb;
}

.mic-active::before,
.mic-active::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid rgb(0, 120, 95);
  border-radius: 50%;
  box-sizing: border-box;
  animation: pulsate 2s linear infinite;
  background-color: unset !important;
}

.mic-active::after {
  animation-delay: 1s;
}

.mic.share {
  font-size: 21px;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(2);
    opacity: 0;
  }
}
</style>
