import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import { db } from "@/common/firebase";
import { RoomNameModel } from "@/shared/model/RoomNameModel";
import store from "@/store";

@Module({ dynamic: true, store: store, name: "RoomNameStore" })
export default class RoomNameStore extends VuexModule {
  roomNames_rns: RoomNameModel[] = [];

  snapshotUnsubscriber_rns: (() => void) | null = null;

  @Action
  async initRoomNames_rns() {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_rns) return;

    try {
      const baseQuery = db.collection("skyway/room/videoRoomNames");

      const snapshots = (await baseQuery.get()).docs;
      const batch = db.batch();
      let deleteFlg = false;
      const now = new Date();
      now.setHours(0, 0, 0, 0);

      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const roomModel = snapshot.data() as RoomNameModel;
        if (!roomModel) continue;

        const expiration = roomModel.expiration.toDate();
        if (expiration < now) {
          batch.delete(snapshot.ref);
          deleteFlg = true;
        } else {
          self.setRoomName({ roomName: roomModel });
        }
      }
      if (deleteFlg) {
        await batch.commit();
      }

      // 変更監視
      const observeQuery = baseQuery.where("expiration", ">=", now);

      self.setSnapshotUnsubscriber_rns({
        snapshotUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(function (x) {
            const room = x.doc.data() as RoomNameModel;
            if (!room) return;

            if (x.type === "added") {
              self.upsertRoom_rns({ room });
            } else if (x.type === "modified") {
              self.upsertRoom_rns({ room });
            } else if (x.type === "removed") {
              self.deleteRoom_rns({ room });
            }
          });
        }),
      });
    } catch (e) {
      throw e as any;
    }
  }

  @Action({ rawError: true })
  destroy_rns() {
    if (this.snapshotUnsubscriber_rns) {
      this.snapshotUnsubscriber_rns();
    }
    this.setSnapshotUnsubscriber_rns({ snapshotUnsubscriber: null });
  }

  @Mutation
  private setRoomName(p: { roomName: RoomNameModel }) {
    this.roomNames_rns.push(p.roomName);
  }

  @Mutation
  private upsertRoom_rns(p: { room: RoomNameModel }) {
    const index = this.roomNames_rns.findIndex((x) => x.roomName === p.room.roomName);
    if (index !== -1) {
      this.roomNames_rns.splice(index, 1, p.room);
    } else {
      this.roomNames_rns.push(p.room);
    }
  }

  @Mutation
  private deleteRoom_rns(p: { room: RoomNameModel }) {
    const index = this.roomNames_rns.findIndex((x) => x.roomName === p.room.roomName);
    if (index !== -1) {
      this.roomNames_rns.splice(index, 1);
    }
  }

  @Mutation
  private setSnapshotUnsubscriber_rns(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_rns = p.snapshotUnsubscriber;
  }
}

export const roomNameStore = getModule(RoomNameStore);
