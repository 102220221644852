import { Module, VuexModule, Mutation, getModule, Action } from "vuex-module-decorators";
import { db, firebase } from "@/common/firebase";
import store from "@/store";
import { userStore } from "@/store/UserStore";
import { ChatData } from "@/common/data/ChatData";
import { FDR } from "@/common/firebaseTypes";
import { ChatModel } from "@/shared/model/ChatModel";
import { ChatLayerModel } from "@/shared/model/ChatLayerModel";
import * as logic from "@/common/logic";
import { ChatMemberModel } from "@/shared/model/ChatMemberModel";

export interface ChatDataTable {
  [id: string]: ChatData;
}

@Module({ dynamic: true, store, name: "chatStore" })
class ChatStore extends VuexModule {
  selectedChatId_cs = "";
  selectedChat_cs: ChatData | null = null;
  isInited_cs = false;

  chats_cs: ChatDataTable = {};
  chatInited_cs: ((chats: ChatDataTable) => void) | null = null;
  chatUpserted_cs: ((chatData: ChatData) => void) | null = null;
  chatDeleted_cs: ((chatData: ChatData) => void) | null = null;

  snapshotUnsubscriber_cs: (() => void) | null = null;

  @Action({ rawError: true })
  async initChats_cs(): Promise<void> {
    const self = this;

    // Early Return
    if (self.snapshotUnsubscriber_cs) return;
    if (!userStore.currentUser_us) throw new Error("ユーザーデータがありません: chatStore");

    const selfId = userStore.currentUser_us.id;

    try {
      const baseQuery = db.collectionGroup("chatMembers").where("userId", "==", selfId);

      // 初回取得
      let maxUpdatedAt: firebase.firestore.Timestamp | null = null;
      const snapshots = (await baseQuery.get()).docs;

      const chatsPromises: Promise<ChatData[]>[] = [];
      let chatsDocs: string[] = [];
      for (let i = 0; i < snapshots.length; i++) {
        const snapshot = snapshots[i];
        const chatDocRef = snapshot.ref.parent.parent as FDR<ChatModel> | null;
        if (!chatDocRef) continue;
        chatsDocs.push(chatDocRef.id);

        // 絞り込みinクエリでMAX30件分のドキュメントを一括取得
        if ((i + 1) % 30 == 0 || i == snapshots.length - 1) {
          const query = db.collection("chats").where(firebase.firestore.FieldPath.documentId(), "in", chatsDocs);
          const chatsPromise = logic.getChatDatas(selfId, this.selectedChatId_cs, query);
          chatsPromises.push(chatsPromise);
          chatsDocs = [];
        }

        // Chatの時刻ではなくChatMemberの時刻であることに注意
        const chatMember = snapshot.data() as ChatMemberModel;
        if (maxUpdatedAt === null || chatMember.updatedAt > maxUpdatedAt) {
          maxUpdatedAt = chatMember.updatedAt;
        }
      }

      const chats = await Promise.all(chatsPromises);
      chats.forEach((e: ChatData[]) => {
        if (e) {
          e.forEach((chatData: ChatData) => {
            if (chatData) {
              self.setChat_cs({ chat: chatData });
            }
          });
        }
      });

      // 一括画面反映
      if (self.chatInited_cs) {
        self.chatInited_cs(self.chats_cs);
      }

      // 変更監視
      const observeQuery = maxUpdatedAt ? baseQuery.where("updatedAt", ">", maxUpdatedAt) : baseQuery;

      self.setSnapshotUnsubscriber_cs({
        snapshotUnsubscriber: observeQuery.onSnapshot(function (snapshot) {
          snapshot.docChanges().forEach(async function (x) {
            // 自分のチャットメンバー情報変更による更新
            const chatDocRef = x.doc.ref.parent.parent as FDR<ChatModel> | null;
            if (!chatDocRef) return;

            const chatId = chatDocRef.id;
            let chat: ChatData | null = self.chats_cs[chatId] || null;

            if (chat) {
              // チャット人数分の処理が走るので、変更時は既存データを利用する
              if (chat.individualInfo && userStore.currentUser_us) {
                chat.individualInfo[selfId].defaultName = userStore.currentUser_us.defaultName;
                chat.individualInfo[selfId].defaultColor = userStore.currentUser_us.defaultColor;
                chat.individualInfo[selfId].imageUri = userStore.currentUser_us.imageUri;
              }
            } else {
              chat = await logic.getChatData(selfId, self.selectedChatId_cs, chatDocRef);
            }

            if (!chat) return;

            if (x.type === "added") {
              self.upsertChat_cs({ chat });
            } else if (x.type === "modified") {
              self.upsertChat_cs({ chat });
            } else if (x.type === "removed") {
              self.deleteChat_cs({ chat });
            }
          });
        }),
      });
    } catch (e) {
      // console.error(e);
      throw e as any;
    }

    self.setIsInited_cs({ isInited: true });
  }

  @Action({ rawError: true })
  selectChat_cs(p: { chat: ChatData | null }) {
    const oldChatId = this.selectedChatId_cs;
    this.setSelectedChat_cs({ chat: p.chat });
    const newChatId = this.selectedChatId_cs;

    if (oldChatId !== newChatId) {
      userStore.onSelectedChatIdChanged_us({ newChatId, oldChatId });
      this.onSelectedChatIdChanged_cs({ newChatId, oldChatId });
    }
  }

  @Action({ rawError: true })
  private upsertChat_cs(p: { chat: ChatData }) {
    this.setChat_cs({ chat: p.chat });

    if (this.chatUpserted_cs) {
      this.chatUpserted_cs(p.chat);
    }
  }

  @Action({ rawError: true })
  private deleteChat_cs(p: { chat: ChatData }) {
    this.unsetChat_cs({ chat: p.chat });

    if (this.chatDeleted_cs) {
      this.chatDeleted_cs(p.chat);
    }
  }

  @Action({ rawError: true })
  adjustChatLayer_cs(p: { chatId: string; chatLayer: ChatLayerModel | null }) {
    const target = this.chats_cs[p.chatId];
    if (target) {
      const chatData = Object.create(target);
      chatData.chatLayer = p.chatLayer;
      this.upsertChat_cs({ chat: chatData });
    }
  }

  @Action({ rawError: true })
  onSelectedChatIdChanged_cs(p: { newChatId: string; oldChatId: string }) {
    if (p.oldChatId) {
      this.setChatIsSeleted_cs({ chatId: p.oldChatId, isSelected: false });
    }

    if (p.newChatId) {
      this.setChatIsSeleted_cs({ chatId: p.newChatId, isSelected: true });
    }
  }

  @Action({ rawError: true })
  destroy_cs(): void {
    if (this.snapshotUnsubscriber_cs) {
      this.snapshotUnsubscriber_cs();
    }

    this.setSnapshotUnsubscriber_cs({ snapshotUnsubscriber: null });
    this.setSelectedChat_cs({ chat: null });
    this.setChatUpserted_cs({ chatUpserted: null });
    this.setChatDeleted_cs({ chatDeleted: null });
    this.setChats_cs({ chats: {} });

    if (this.chatInited_cs) {
      this.chatInited_cs(this.chats_cs);
    }

    this.setChatInited_cs({ chatInited: null });
    this.setIsInited_cs({ isInited: false });
  }

  @Mutation
  private setChats_cs(p: { chats: ChatDataTable }) {
    this.chats_cs = p.chats;
  }

  @Mutation
  private setChat_cs(p: { chat: ChatData }) {
    this.chats_cs[p.chat.id] = p.chat;
  }

  @Mutation
  private unsetChat_cs(p: { chat: ChatData }) {
    delete this.chats_cs[p.chat.id];
  }

  @Mutation
  private setChatIsSeleted_cs(p: { chatId: string; isSelected: boolean }) {
    if (this.chats_cs[p.chatId]) {
      this.chats_cs[p.chatId].isSelected = p.isSelected;
    }
  }

  @Mutation
  private setSelectedChat_cs(p: { chat: ChatData | null }) {
    this.selectedChat_cs = p.chat;
    this.selectedChatId_cs = p.chat?.id || "";
  }

  @Mutation
  setChatInited_cs(p: { chatInited: ((chats: ChatDataTable) => void) | null }) {
    this.chatInited_cs = p.chatInited;
  }

  @Mutation
  setChatUpserted_cs(p: { chatUpserted: ((chat: ChatData) => void) | null }) {
    this.chatUpserted_cs = p.chatUpserted;
  }

  @Mutation
  setChatDeleted_cs(p: { chatDeleted: ((chat: ChatData) => void) | null }) {
    this.chatDeleted_cs = p.chatDeleted;
  }

  @Mutation
  private setSnapshotUnsubscriber_cs(p: { snapshotUnsubscriber: (() => void) | null }) {
    this.snapshotUnsubscriber_cs = p.snapshotUnsubscriber;
  }

  @Mutation
  private setIsInited_cs(p: { isInited: boolean }) {
    this.isInited_cs = p.isInited;
  }
}

export const chatStore = getModule(ChatStore);
