<template>
  <edit-dialog
    :isDisplay="true"
    :isCancel="true"
    title="デバイス設定"
    okButtonText="OK"
    cancelButtonText="キャンセル"
    @okClicked="onMediaOkClicked"
    @cancelClicked="onMediaCancelClicked"
  >
    <v-row>
      <v-select v-model="audio" :items="audioItems" item-text="label" item-value="deviceId" label="マイク"></v-select>
    </v-row>
  </edit-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import EditDialog from "@/components/EditDialog.vue";

interface MediaData {
  deviceId: string;
  label: string;
}

@Component({ components: { EditDialog } })
export default class MediaSettings extends Vue {
  @Prop({ default: "" })
  audioId!: string;

  audio = "";
  audioItems: MediaData[] = [];

  async setDeviceItem() {
    this.audioItems = [{ deviceId: "", label: "選択してください" }];

    await navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(async (stream) => {
        await navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              const label = device.label;
              const deviceId = device.deviceId || device.kind;

              if (device.kind === "audioinput") {
                const text = "（マイク" + this.audioItems.length + "）";
                const audioItem = { deviceId: deviceId, label: label ? label : text };
                this.audioItems.push(audioItem);
              }
            });
          })
          .finally(() => {
            const isAudio = this.audioItems.some((x) => x.deviceId === this.audioId);
            if (!isAudio) {
              this.audio = "";
            }
          });
        stream.getTracks().forEach((track) => track.stop());
      })
      .catch(() => {
        return false;
      });
  }

  async displayMediaSetting() {
    this.audio = this.audioId;
    await this.setDeviceItem();
  }

  async onMediaOkClicked() {
    if (!this.audio) {
      if (this.audioItems.length <= 1) await this.setDeviceItem();
      return;
    }
    this.$emit("ok", this.audio);
  }

  onMediaCancelClicked() {
    this.$emit("cancel");
  }

  async mounted() {
    await this.displayMediaSetting();
  }

  beforeDestroy() {
    this.audioItems = [];
  }
}
</script>
<style type="sscs"></style>
