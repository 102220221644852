<template>
  <div id="container">
    <!-- テロップ -->
    <transition name="telop">
      <simple-button v-show="isTelopDisplay" class="telop-icon" :class="{ mobile: !isPc }">
        {{ telopMessage }}
      </simple-button>
    </transition>

    <!-- オーバーレイ -->
    <v-overlay opacity="0" :value="isDisplayOverray" absolute>
      <v-card class="pa-3" color="#80808030" flat>
        <v-progress-circular indeterminate size="40" width="4"></v-progress-circular>
      </v-card>
    </v-overlay>

    <!-- 切断中メッセージ -->
    <label v-if="isActive && isCallEnd" class="gct-normal--text cutting-text">切断中…</label>

    <!-- 通話時間 -->
    <timer id="topbar" ref="topbar" className="pl-5"></timer>

    <!-- ビデオエリア -->
    <div id="videoArea" ref="videoArea" :class="{ share: isActive && isShare, noVideo: isShare }">
      <!-- ルーム -->
      <template v-if="param.callType === 'videoRoom'">
        <!-- 共有画面表示 -->
        <div v-show="isActive && isShare" id="shareVideoDiv" ref="shareVideoDivRef">
          <video class="share-video" autoplay playsinline id="shareVideo"></video>
        </div>

        <template v-if="isActive">
          <div class="opp-video-container" :class="{ share: isShare }" ref="oppVideoArea">
            <!-- 相手表示 -->
            <video-chat-frame
              v-for="member in members"
              :isDisplay="member.isDisplay"
              :member="member"
              :avatarSize="isShare ? avatarMinSize : avatarSize"
              :key="member.peerId"
              :isShare="isShare"
            ></video-chat-frame>
          </div>

          <!-- 自分表示 -->
          <video-chat-frame
            v-if="myData"
            ref="myVideo"
            class="my-video"
            :member="myData"
            :avatarSize="avatarMinSize"
            key="myData.peerId"
            videoFrameType="mine"
          ></video-chat-frame>
        </template>

        <!-- 待機中 -->
        <template v-else>
          <div class="opp-video-container" ref="oppVideoArea">
            <div v-if="myData" class="waiting-style" id="waiting">
              <video-chat-frame
                :member="myData"
                :avatarSize="200"
                :key="myData.peerId + '_waiting'"
                videoFrameType="waiting"
              ></video-chat-frame>
              <label v-if="!isCallEnd" class="gct-normal--text waiting-text">入出待機中…</label>
              <!-- 待機中での切断中メッセージ -->
              <label v-else class="gct-normal--text waiting-text">切断中…</label>
            </div>
          </div>
        </template>
      </template>
    </div>

    <!-- コントローラ -->
    <div id="controller" ref="controller" class="d-flex justify-center" :style="{ zIndex: isActive ? 0 : 10 }">
      <div class="d-flex align-center px-3 gc-controller controller">
        <!-- ミュート -->
        <icon-button :isPc="isPc" tooltipText="ミュート" @click="onVoiceMute">
          <v-icon v-if="isVoiceMute" color="red" class="material-icons-round" :disabled="isNoMic">mic_off</v-icon>
          <v-icon v-else color="accent" class="material-icons-round" :disabled="isNoMic">mic</v-icon>
        </icon-button>
        <!-- 画面共有 -->
        <icon-button v-if="isActive" :isPc="isPc" tooltipText="画面共有" @click="onShare()">
          <v-icon v-if="shareId !== myPeerId" color="accent" class="material-icons-round">screen_share</v-icon>
          <v-icon v-else color="red" class="material-icons-round">stop_screen_share</v-icon>
        </icon-button>
        <!-- 通話終了 -->
        <icon-button :isPc="isPc" tooltipText="通話終了" @click="onCallEnd">
          <v-icon color="red" class="material-icons-round">call_end</v-icon>
        </icon-button>
        <!-- 設定 -->
        <template v-if="isMediaSettingsIcon">
          <margin-vertical-divider :class="{ 'ml-4': isPc }"></margin-vertical-divider>
          <icon-button :isPc="isPc" tooltipText="設定" @click="isMediaSettings = true">
            <v-icon color="accent" class="material-icons-round">settings</v-icon>
          </icon-button>
        </template>
      </div>
    </div>

    <!-- デバイス設定 dialog -->
    <media-settings
      v-if="isMediaSettings"
      ref="mediaSetting"
      @ok="mediaSetOkClicked"
      @cancel="isMediaSettings = !isMediaSettings"
      :audioId="micId"
    ></media-settings>

    <!-- 初期設定 dialog -->
    <edit-dialog
      :isDisplay="initialSettings"
      :isCancel="false"
      :maxWidth="300"
      okButtonText="OK"
      @okClicked="meshRoomJoin"
    >
      <key-setting>
        <template v-slot:key>
          <v-icon v-if="isVoiceMute" class="material-icons-round">mic_off</v-icon>
          <v-icon v-else class="material-icons-round">mic</v-icon>
        </template>
        <template v-slot:setting>
          <simple-switch @change="onVoiceMute" :inputValue="isVoiceMute" :disabled="isNoMic" label="マイクをミュート" />
        </template>
      </key-setting>
    </edit-dialog>

    <!-- ノイズ除去用（加工前音声の無効用） -->
    <div id="noiseCancel"></div>
  </div>
</template>

<script lang="ts">
import Peer from "skyway-js";
import { Component, Vue } from "vue-property-decorator";
import { sendMessage } from "@/common/deliveryUtil";
import { db } from "@/common/firebase";
import * as util from "@/common/util";
import EditDialog from "@/components/EditDialog.vue";
import IconButton from "@/components/IconButton.vue";
import KeySetting from "@/components/KeySetting.vue";
import MarginVerticalDivider from "@/components/MarginVerticalDivider.vue";
import MediaSettings from "@/components/MediaSettings.vue";
import SimpleButton from "@/components/SimpleButton.vue";
import SimpleSwitch from "@/components/SimpleSwitch.vue";
import Timer from "@/components/Timer.vue";
import VideoChatFrame from "@/components/VideoChatFrame.vue";
const hark = require("hark") as any;

export interface UserIcon {
  imageUri: string;
  text: string;
  color: string;
  textColor: string;
}

interface SendData {
  type: string;
  userId: string;
  userName: string;
  userIcon: UserIcon;
  muted: boolean;
}

export interface Member {
  peerId: string;
  userId: string;
  userName: string;
  userIcon: UserIcon | null;
  stream: MediaStream | null;
  muted: boolean;
  isDisplay: boolean;
  isConnect: boolean;
  isSpeak: boolean;
}

export interface CallParam {
  callType: string;
  userId: string;
  oppId: string;
  roomName?: string;
  userName: string;
  userIcon: UserIcon;
  isHost: boolean;
}

@Component({
  name: "VideoChat",
  components: {
    SimpleButton,
    VideoChatFrame,
    IconButton,
    MarginVerticalDivider,
    MediaSettings,
    EditDialog,
    Timer,
    KeySetting,
    SimpleSwitch,
  },
})
export default class VideoChat extends Vue {
  $refs!: {
    topbar: Timer | any;
    videoArea: HTMLDivElement;
    controller: HTMLDivElement;
    myVideo: HTMLVideoElement;
    oppVideoArea: HTMLDivElement;
    mediaSetting: MediaSettings;
    shareVideoDivRef: HTMLDivElement;
  };

  // 画面からのParam
  param: CallParam | any = {};

  // SkyWay関連
  peer: any = {};
  localStream: MediaStream | null = null;

  myPeerId = "";

  // コントローラ切替
  isVoiceMute = false;
  isMediaSettings = false;
  get isMediaSettingsIcon() {
    return !!this.myData && !!this.myData.stream;
  }
  isNoMic = true;

  get isMobile() {
    return this.$vuetify.breakpoint.mobile;
  }
  get isPc() {
    return !this.isMobile;
  }

  get isShare() {
    // スクリーン選択時は共有表示にしない（無限ループ防止）
    if (this.shareId === this.myPeerId) {
      return false;
    }
    return !!this.shareId;
  }

  // Room Videoエリア・アイコンサイズ（デフォルト値設定）
  windowMinWidth = 500;
  windowMinHeight = 400;
  avatarSize = 100;
  avatarMinSize = 56;
  avatarMaxSize = 170;
  videoWidth = 100;
  videoHeight = 170;

  // meshRoom関連
  members: Member[] = [];
  meshRoom: any = null;
  myData: Member | null = null;
  isActive = false;
  hasStream = false;
  sendData: SendData = {
    type: "",
    userName: "",
    userId: "",
    userIcon: { imageUri: "", text: "", color: "", textColor: "" },
    muted: false,
  };
  isCallEnd = false;

  // 元のメンバーstreamを保持しておく
  streams: MediaStream[] = [];

  // 画面共有
  shareId = "";
  shareMedia: MediaStream | null = null;

  // 表示メンバー切替
  memberMaxDispNum = 1;

  myUserId = "";

  // 退室メッセージ送信済フラグは、true にしておく
  // （MeshRoom 入室前に通話終了した場合はメッセージ不要なため）
  exitMessageSentFlag = true;

  // テロップ
  telopMessage = "";
  isTelopDisplay = false;

  // デバイス設定
  micId = "";
  initialSettings = false;

  isDisplayOverray = false;

  // 入室済みフラグ
  isDuplicates = false;

  loginId = "";

  // 初期表示またはリサイズ時に、windowサイズとビデオエリアのレイアウト調整をする
  setVideoAreaLayout() {
    this.adjustWinSize();
    if (this.param.callType === "videoRoom") {
      this.adjustMemberSizeAndLayout();
    }
  }

  // ウィンドウサイズ調整
  adjustWinSize() {
    // window で利用可能なサイズを取得（スマホなど小さい機器も考慮するため）
    const screenW = window.screen.availWidth;
    const screenH = window.screen.availHeight;

    // 利用可能なサイズか、定数の最小サイズどちらか小さい方を取得
    const minW = screenW < this.windowMinWidth ? screenW : this.windowMinWidth;
    const minH = screenH < this.windowMinHeight ? screenH : this.windowMinHeight;

    // 表示画面サイズ取得
    const innerW = window.innerWidth;
    const innerH = window.innerHeight;

    // 表示画面サイズが最小サイズ以上なら中断
    if (minW <= innerW && minH <= innerH) return;

    // ウィンドウサイズを調整する
    const w = window.outerWidth;
    const h = window.outerHeight;
    const adjustW = w - innerW;
    const adjustH = h - innerH;

    if (innerW < minW && innerH < minH) {
      window.resizeTo(minW + adjustW, minH + adjustH);
    } else if (innerH < minH) {
      window.resizeTo(w, minH + adjustH);
    } else if (innerW < minW) {
      window.resizeTo(minW + adjustW, h);
    }
  }

  // メンバーのサイズとレイアウト調整
  adjustMemberSizeAndLayout() {
    // ビデオエリアのサイズ取得
    let w = this.$refs.videoArea.offsetWidth;
    let h = this.$refs.videoArea.offsetHeight;
    // ビデオエリアが小さい場合、最小値を設定
    if (w < this.windowMinWidth) {
      w = this.windowMinWidth;
    }
    if (h < this.windowMinHeight) {
      h = this.windowMinHeight;
    }

    // ※1vs1通話なので行数・列数は固定
    const row = 1;
    const col = 1;

    // その他高さ（ビデオエリア外）
    const topbarElem = document.getElementById("topbar") as HTMLDivElement;
    const other = topbarElem.offsetHeight + this.$refs.controller.offsetHeight;

    // ビデオ高さ = (ビデオエリア高さ - その他高さ - (ユーザー名 + アバター余白 + 調整) * 行数) / 行数
    const videoH = (h - other - (26 + 10 + 7) * row) / row;
    this.videoHeight = videoH;

    // ビデオ幅 = (画面幅 - ビデオエリアpadding - アバター余白 * 列数) / 列数
    const videoW = (w - 40 - 10 * col) / col;
    this.videoWidth = videoW;

    // アバターサイズ
    this.avatarSize = this.avatarMaxSize < videoW ? this.avatarMaxSize : videoW;
  }

  getParam() {
    try {
      const vcParam: any = localStorage.getItem("vcParam");
      return JSON.parse(vcParam);
    } catch (e) {
      return "";
    }
  }

  // SkyWay 接続用の PeerID を取得（自分用）
  async getMyPeerId(): Promise<string> {
    let result = "";
    const userId = this.myUserId;
    const swSnap = (await db.collection(`users/${userId}/swPeerIds`).get()).docs;

    for (const doc of swSnap) {
      const docObj = doc.data();
      const swPeerId = docObj.swPeerId[this.loginId];
      if (swPeerId) {
        result = swPeerId;
        break;
      }
    }

    return result;
  }

  async saveMyPeerId(): Promise<void> {
    const userId = this.myUserId;
    const swSnap = await db.collection(`/users/${userId}/swPeerIds`).get();
    let docRef = null;
    if (swSnap.empty) {
      docRef = db.collection(`/users/${userId}/swPeerIds`).doc();
    } else {
      const doc = swSnap.docs[0];
      docRef = doc.ref;
    }
    const myPeerId = { [this.loginId]: this.myPeerId };
    await docRef.set({ swPeerId: myPeerId, updateAt: util.now() }, { merge: true });
  }

  // ミュート切り替えボタン押下
  async onVoiceMute() {
    // マイクの有無チェック
    if (!this.isNoMic) {
      await navigator.mediaDevices.enumerateDevices().then(async (devices) => {
        // 利用可能なマイクの有無チェック
        this.isNoMic = devices.findIndex((x) => x.kind === "audioinput") === -1;
        // デバイス設定済みマイクの有無チェック
        // ※入室時は自動検出したデバイスを使用するため、deviceId の確認はできない（入室時の micId には audioinput か空白が設定される）
        if (!(this.micId === "audioinput" || this.micId === "")) {
          this.isNoMic = devices.findIndex((x) => x.deviceId === this.micId) === -1;
        }
      });
    }

    if (!this.localStream || (this.localStream && this.localStream.getAudioTracks().length === 0)) return;

    // ミュート切り替え
    this.isVoiceMute = !this.isVoiceMute;
    if (this.isNoMic) {
      this.isVoiceMute = true;
    }
    this.deviceSwitching("voice");
  }

  deviceSwitching(target: string) {
    if (!this.myData) return;

    // 自分のデータを変更
    this.myData.muted = this.isVoiceMute;
    // ルームメンバーへの送信データを変更
    if (target === "voice") {
      this.sendData.muted = this.myData.muted;
    }

    // ストリームのミュートを切り替える
    if (this.localStream) {
      if (this.myData.muted) {
        this.localStream.getAudioTracks().forEach((track) => (track.enabled = false));
      }
      if (!this.myData.muted) {
        this.localStream.getAudioTracks().forEach((track) => (track.enabled = true));
      }
    }

    if (!this.isActive || !this.meshRoom) return;

    // ルームメンバーへ送信
    this.sendData.type = target;
    this.meshRoom.send(this.sendData);
  }

  // 画面共有ボタン押下
  async onShare() {
    if (this.shareId !== this.myPeerId) {
      await this.settingUpShare();
    } else {
      this.onStopScreenShare();
    }
  }
  async settingUpShare() {
    try {
      this.isDisplayOverray = true;
      const message = await this.roomScreenShare();
      if (message !== "") {
        await util.showDialog({
          title: "",
          dialogText: message,
        });
      }
    } finally {
      this.isDisplayOverray = false;
    }
  }

  async roomScreenShare(): Promise<string> {
    // すでに画面共有中なら中断する
    if (this.shareId) {
      return "通話相手が画面共有中です<br/>解除後にご利用ください";
    }

    if (!this.myData) return "";

    const unavailableMessage = "お使いのブラウザではご利用できません";

    // getDisplayMedia に互換性のない OS は利用不可
    const ua = window.navigator.userAgent.toLowerCase();
    if (ua.indexOf("android") > 0 || ua.indexOf("iphone") > 0 || ua.indexOf("ipad") > 0) {
      return unavailableMessage;
    }

    let mediaDevise: MediaStream | null = null;
    try {
      // ビデオなしの状態で、アクセス許可されてない場合は利用不可
      let noVideoState = "";
      await navigator.mediaDevices.getDisplayMedia({ video: false }).catch((e: any) => {
        noVideoState = e.name;
      });
      if (noVideoState === "NotAllowedError") {
        return unavailableMessage;
      }

      // 共有画面を選択
      mediaDevise = await navigator.mediaDevices.getDisplayMedia({ video: true }).then((target: MediaStream) => {
        // この段階で画面共有中になっていたら中断する
        if (this.shareId) {
          this.stopStreamTracks(target);
          return null;
        }

        return target;
      });
    } catch (e: any) {
      if (e.name === "NotAllowedError") {
        return "キャンセルされたか、またはご利用できません";
      }
      mediaDevise = null;
    }

    const media: MediaStream | null = mediaDevise;

    // 共有画面の取得に失敗したら中断する
    if (media === null) {
      if (!this.shareId) {
        return unavailableMessage;
      }
      // 共有画面の選択中に画面共有されたので、特にメッセージはいらない
      return "";
    }

    // 共有画面の終了イベント
    media.getTracks()[0].addEventListener("ended", () => {
      this.onStopScreenShare();
    });

    // 使用中のオーディオを共有画面の MediaStream に設定（同じオーディオを使い続けるため）
    if (this.localStream) {
      this.localStream.getAudioTracks().forEach((track: any) => {
        media.addTrack(track);
      });
    }

    // デバイス設定用に保持して置く
    this.shareMedia = media;

    // 共有画面の設定
    this.shareId = this.myPeerId;
    this.setShareStream(media);
    this.meshRoom.replaceStream(media);

    // replaceStream が完了してからデータを送信する
    setTimeout(() => {
      this.sendData.type = "share";
      this.meshRoom.send(this.sendData);
    }, 1000);

    return "";
  }

  // 画面共有停止ボタン押下
  onStopScreenShare() {
    // 自分が画面共有した場合、停止する
    if (this.shareId !== this.myPeerId) return;

    // 共有画面の解除
    this.shareId = "";
    this.setShareStream(null);
    this.meshRoom.replaceStream(this.localStream);

    // replaceStream が完了してからデータを送信する
    setTimeout(() => {
      this.sendData.type = "noShare";
      this.meshRoom.send(this.sendData);
    }, 1000);
  }

  // 通話終了ボタン押下
  async onCallEnd() {
    // 退室メッセージ未送信の場合、退室の処理をする
    if (!this.exitMessageSentFlag) {
      await this.exitMessage();
      await this.deleteRoomName();
    }

    // 通常終了なのでイベント不要
    window.removeEventListener("beforeunload", this.closeEvent);

    // 通話終了
    await this.callEnd();
  }

  async callEnd() {
    try {
      // ボタンの連打防止
      this.isDisplayOverray = true;
      // 切断中メッセージ表示
      this.isCallEnd = true;

      if (this.param.callType === "videoRoom") {
        if (this.meshRoom) {
          // 自分が開催者の場合、メンバーを強制退室させる
          if (this.param.isHost && !this.isDuplicates) {
            this.sendData.type = "close";
            this.meshRoom.send(this.sendData);
          }
          // 退室しメンバーとのコネクションを切断
          this.meshRoom.close();
        }
      }

      // シグナリングサーバー・mediaConnection・dataConnectionを切断
      if (this.peer.open) {
        this.peer.destroy();
      }

      // 満室メッセージ
      if (this.isDuplicates) {
        await util.showDialog({ title: "", dialogText: "このルームは満室です。" });
      }
    } catch (e) {
      // エラーにさせない
    }

    // 通話終了後にウィンドウを閉じる
    setTimeout(() => {
      window.close();
    }, 1000);
  }

  // 閉じるイベント（主にブラウザの閉じるボタン押下時の処理）
  async closeEvent() {
    // 通話終了
    await this.callEnd();

    // localStorage に削除するルーム名をセットする（退室後にルーム名を削除するため）
    if (this.isActive && this.param.isHost && this.param.callType === "videoRoom") {
      localStorage.setItem("deleteRoomName", this.param.roomName);
    }

    // 退室メッセージ送信
    if (!this.exitMessageSentFlag) {
      await this.exitMessage();
    }
  }

  // ルーム名を削除
  async deleteRoomName() {
    if (this.param.isHost) {
      const query = await db
        .collection("skyway/room/videoRoomNames")
        .where("roomName", "==", this.param.roomName)
        .where("createdUserId", "==", this.myUserId)
        .get();
      query.docs.forEach((doc) => {
        doc.ref.delete();
      });
    }
  }

  // デバイス設定 OK ボタン押下
  async mediaSetOkClicked(micId: string) {
    const result = await this.setMedia(micId);
    // デバイス設定に失敗した場合、設定ダイアログを再表示する
    if (!result) {
      await util.showDialog({ title: "エラー", dialogText: "デバイスの設定に失敗しました" });
      this.isMediaSettings = true;
    }
  }

  async setMedia(micId: string) {
    // デバイス設定ダイアログは閉じる
    this.isMediaSettings = false;

    // メディア取得オプション（入室時は自動検出したデバイスを使用）
    const option = { audio: micId === "audioinput" ? true : { deviceId: { exact: micId } } };

    // ストリームを取得
    const stream = await navigator.mediaDevices.getUserMedia(option).catch(() => {
      return null;
    });
    if (!stream) {
      return false;
    }

    this.micId = micId;

    // 現在使用中のストリームは停止しておく
    if (this.localStream) {
      this.stopStreamTracks(this.localStream);
    }
    this.localStream = null;
    if (this.myData && this.myData.stream) {
      this.myData.stream = null;
    }

    // ダミーのビデオトラックを仕込んでおく（MediaStream の映像有無を途中で変更できないため）
    const track = util.getCreateDummyVideoTrack();
    stream.addTrack(track);

    // 新しいストリームに変更する
    this.setMediaStream(stream);
    this.isNoMic = false;
    return true;
  }

  // ストリームを変更する
  setMediaStream(stream: MediaStream) {
    // 自分のデータを変更
    this.localStream = stream;
    if (this.myData) {
      this.myData.stream = stream;
    }

    if (this.meshRoom) {
      // 送信中の MediaStream 変更
      if (this.shareId === this.myPeerId && this.shareMedia) {
        // 自分が画面共有してる場合、オーディオトラックを差し替える
        const media = new MediaStream();
        this.shareMedia.getVideoTracks().forEach((track) => media.addTrack(track));
        stream.getAudioTracks().forEach((track) => media.addTrack(track));

        this.meshRoom.replaceStream(media);
      } else {
        this.meshRoom.replaceStream(stream);
      }
    }

    this.deviceSwitching("voice");
  }

  // ルーム入室前の設定
  async settingsBeforeRoomJoin() {
    // 自動検出したデバイスをセットする
    this.localStream = null;
    const result = await this.setMedia("audioinput");

    // デバイスが無くても入室可（ダミーのストリームを仕込む）
    if (!result) {
      this.isVoiceMute = true;
      const stream = util.getCreateDummyMediaStream();
      this.setMediaStream(stream);
    }

    // 初期設定ダイアログを表示
    this.initialSettings = true;
  }

  async meshRoomJoin(): Promise<void> {
    // 初期設定は終了
    this.initialSettings = false;
    if (!this.peer.open) return;

    this.members = [];
    try {
      this.isDisplayOverray = true;

      // ルームに参加
      this.meshRoom = this.peer.joinRoom(this.param.roomName, {
        mode: "mesh",
        stream: this.localStream,
      });

      // ルームへの送信用データ準備
      this.sendData = {
        type: "newMember",
        userName: this.param.userName,
        userId: this.param.userId,
        userIcon: this.param.userIcon,
        muted: this.isVoiceMute,
      };

      // ルーム入室処理
      this.meshRoom.on("open", async () => {
        // タイマー開始
        this.$refs.topbar.timerStart();

        // 入室通知を表示
        await this.sendNotification(`入室しました`);

        // メンバーに入室を知らせる
        this.meshRoom.send(this.sendData);

        // 自分が開催者の場合、videoRoomNames テーブルを更新する
        if (!this.param.isHost) return;
        const snap = await db
          .collection("skyway/room/videoRoomNames")
          .where("roomName", "==", this.param.roomName)
          .where("createdUserId", "==", this.myUserId)
          .get();
        snap.docs.forEach(async (doc) => {
          await doc.ref.set({ startTime: util.now(), updatedAt: util.now() }, { merge: true });
        });
      });

      // 新規メンバー参加処理
      this.meshRoom.on("peerJoin", (peerId: string) => {
        if (!peerId) return;
        // メンバーに自分の情報を知らせる
        this.sendData.type = this.shareId === this.myPeerId ? "share" : "info";
        this.meshRoom.send(this.sendData);
      });

      // メンバー退室処理
      this.meshRoom.on("peerLeave", async (peerId: string) => {
        // 画面共有者が退室したら、共有画面は解除する
        if (this.shareId === peerId) {
          this.shareId = "";
          this.setShareStream(null);
        }

        // メンバー退室のテロップ表示（定員オーバーの場合、テロップ不要）
        const user = this.members.find((x) => x.peerId === peerId);
        if (user && user.userId) {
          this.displayTelop("peerLeave", user.userName ?? "メンバー");
        }

        // 退室メンバーを除外する
        if (user && user.stream) {
          // ストリームは停止しておく
          this.stopStreamTracks(user.stream);
          user.stream = null;
        }
        this.members = this.members.filter((x) => x.peerId !== peerId);

        // 元のストリームを除外する
        const stream = this.streams.find((x: any) => x.peerId === peerId);
        if (stream) {
          this.stopStreamTracks(stream);
          this.streams = this.streams.filter((x: any) => x.peerId !== peerId);
        }

        // 退室メンバーの harker は停止してから除外する
        const index = this.harkers.findIndex((x) => x.peerId === peerId);
        if (index !== -1) {
          this.harkers[index].harker.stop();
          this.harkers.splice(index, 1);
        }
      });

      // メンバーのストリーム受信処理
      this.meshRoom.on("stream", async (stream: any) => {
        // 元のストリームは保持しておく
        this.streams.push(stream);

        // ストリームのノイズ除去
        const filterStream = this.getNoiseFilterStream(stream, "new");

        // メンバーのストリーム追加と表示変更
        this.setMembersStream(filterStream);
        this.setHarker(stream);
        this.changeMemberDisplay();

        // 退室時の処理フラグを設定
        if (this.exitMessageSentFlag) {
          this.exitMessageSentFlag = false;
        }
      });

      // メンバーのデータ受信処理
      this.meshRoom.on("data", async ({ src, data }: { src: string; data: SendData }) => {
        const peerId = src;
        const { userId, type, userName, userIcon, muted } = data;

        // 自分と同じユーザーが既に入室していたら強制退室する
        const isExist = this.myData?.userId === userId;
        if (isExist) {
          if (type === "info") {
            this.isDuplicates = true;
            await this.callEnd();
          }
          return;
        }

        // 開催者が退室した場合、メンバーは強制退室させる
        if (type === "close") {
          await util.showDialog({ title: "", dialogText: "開催者が退室したためルームを閉じます" });
          await this.exitMessage();
          await this.callEnd();
          return;
        }

        // メンバーデータを取得変更
        const targetMember = this.members.find((x) => x.peerId === peerId);
        const member: Member = {
          peerId: peerId,
          userId: targetMember?.userId || userId,
          userName: targetMember?.userName || userName,
          userIcon: targetMember?.userIcon || userIcon,
          stream: targetMember?.stream || null,
          muted: muted ?? true,
          isDisplay: targetMember?.isDisplay || false,
          isConnect: targetMember ? !!targetMember.stream : false,
          isSpeak: targetMember?.isSpeak || false,
        };

        // 元のストリーム
        const originStream = this.streams.find((stream: any) => stream.peerId === member.peerId);

        // 変更されたストリームのノイズ除去
        if (member.stream) {
          if (type === "voice" || type === "share" || type === "noShare") {
            member.stream = this.getNoiseFilterStream(originStream);
          }
        }

        // メンバーデータを更新
        if (!targetMember) {
          this.members.unshift(member);
        } else {
          const index = this.members.findIndex((x) => x.peerId === peerId);
          if (index !== -1) {
            this.members.splice(index, 1, member);
            this.setHarker(originStream);
          }
        }

        // 画面共有の設定と解除
        if (type === "share") {
          // すでに共有中なら何もしない
          if (this.shareId) return;
          // 設定する
          this.setShareStream(member.stream);
          this.shareId = peerId;
        }
        if (type === "noShare") {
          // 解除する
          this.shareId = "";
          this.setShareStream(null);
        }

        // ユーザ名が編集されていたら変更する（初回メンバー追加時のみ）
        // 非同期処理のためメンバー更新後に行う
        let editedName = "";
        if (!targetMember || !targetMember.userName) {
          editedName = await this.setEditedUserNameToMember(peerId, userId);
        }

        // 新メンバー参加のテロップ表示
        if (type === "newMember") {
          this.displayTelop("peerJoin", editedName ? editedName : userName);
        }

        if (!this.isActive) {
          this.isActive = true;
          this.isDisplayOverray = false;
        }

        this.changeMemberDisplay();
      });

      // ルーム退室処理
      this.meshRoom.on("close", () => {
        // ストリームとメディア要素は停止および削除する
        this.stopMediaStream();
      });
    } catch (e) {
      // throw しない
    }
  }

  // ノイズ除去したストリームを取得
  audioContexts: { peerId: string; audioContext: AudioContext }[] = [];
  getNoiseFilterStream(stream: any, type?: string) {
    const audioContext = new AudioContext();
    const source = audioContext.createMediaStreamSource(stream);

    // ノイズ除去用のフィルターを設定
    // lowpass
    const lowpass = audioContext.createBiquadFilter();
    lowpass.type = "lowpass";
    lowpass.frequency.value = 4000;
    // lowshelf
    const lowshelf = audioContext.createBiquadFilter();
    lowshelf.type = "lowshelf";
    lowshelf.frequency.value = 300;
    lowshelf.gain.value = -8;
    // highshelf
    const highshelf = audioContext.createBiquadFilter();
    highshelf.type = "highshelf";
    highshelf.frequency.value = 2800;
    highshelf.gain.value = -15;
    // higshelf2
    const highshelf2 = audioContext.createBiquadFilter();
    highshelf2.type = "highshelf";
    highshelf2.frequency.value = 3400;
    highshelf2.gain.value = -30;

    // 接続
    source.connect(lowpass);
    lowpass.connect(lowshelf);
    lowshelf.connect(highshelf);
    highshelf.connect(highshelf2);
    highshelf2.connect(audioContext.destination);

    // 音声コンテキストを保持する
    const index = this.audioContexts.findIndex((x: any) => x.peerId === stream.peerId);
    if (index === -1) {
      this.audioContexts.push({ peerId: stream.peerId, audioContext });
    } else {
      // 作成済みの音声コンテキストは閉じておく
      this.audioContexts[index].audioContext.close();
      this.audioContexts[index].audioContext = audioContext;
    }

    // 元のストリーム音声を無効化しておく
    if (type === "new") {
      const div = document.getElementById("noiseCancel");
      if (div) {
        const audio = document.createElement("audio");
        audio.id = `noiseCancel_${stream.peerId}`;
        audio.muted = true;
        audio.autoplay = true;
        div.appendChild(audio);
        audio.srcObject = stream;
      }
    }

    // 処理済みのストリームを取得
    const filterStream: any = audioContext.createMediaStreamDestination().stream;
    filterStream.peerId = stream.peerId;

    // 処理済みのストリームにビデオトラックを追加
    stream.getVideoTracks().forEach((track: any) => {
      filterStream.addTrack(track);
    });

    return filterStream;
  }

  // メンバーの表示変更
  changeMemberDisplay() {
    this.sortMembers();
    this.switchMemberDisplay();
  }

  sortMembers() {
    // メンバーの接続順で並び替え
    this.members.sort(function (a, b) {
      if (a.isConnect && !b.isConnect) return -1;
      if (!a.isConnect && b.isConnect) return 1;
      return 0;
    });
  }

  switchMemberDisplay() {
    // 表示メンバーの切り替え
    let cnt = 0;
    this.members.forEach((member) => {
      // メンバーの接続が切れてたら非表示にする
      if (!member.isConnect) {
        member.isDisplay = false;
      }
      // 表示上限を超えたら、以降のメンバーは非表示にする
      member.isDisplay = cnt < this.memberMaxDispNum;
      cnt++;
    });
  }

  // メンバーのストリーム設定
  setMembersStream(stream: any) {
    // メンバーが画面共有してる場合、共有画面の設定をする
    if (this.shareId === stream.peerId) {
      this.setShareStream(stream);
    }

    const peerId = stream.peerId;
    const member = this.members.find((x) => x.peerId === peerId);
    if (!member) {
      // メンバーデータ追加
      this.addMember(peerId, stream);
    } else {
      // メンバーデータ更新
      member.stream = stream;
      member.isConnect = true;
      const index = this.members.findIndex((x) => x.peerId === peerId);

      this.members.splice(index, 1, member);
    }
  }

  addMember(peerId: string, stream?: MediaStream) {
    const member: Member = {
      peerId: peerId,
      userId: "",
      userName: "",
      userIcon: null,
      stream: stream ?? null,
      muted: true,
      isDisplay: false,
      isConnect: false,
      isSpeak: false,
    };
    this.members.unshift(member);
  }

  // 画面共有の設定
  setShareStream(stream: MediaStream | null) {
    // 共有用のビデオ要素を取得
    const shareVideo = document.getElementById("shareVideo") as HTMLVideoElement;
    if (stream) {
      // 設定
      const mediaStream = new MediaStream();
      stream.getVideoTracks().forEach((track) => mediaStream.addTrack(track));
      if (shareVideo) {
        shareVideo.srcObject = mediaStream;
      }
    } else {
      // 解除
      if (this.shareMedia) {
        // 共有用 MediaStream のビデオトラックのみ停止する
        this.shareMedia.getVideoTracks()[0].stop();
        this.shareMedia.getVideoTracks().forEach((track) => track.stop());
        this.shareMedia = null;
      }
      if (shareVideo && shareVideo.srcObject) {
        shareVideo.srcObject = null;
      }
    }

    // 共有用のビデオ要素はミュートにしておく
    if (shareVideo) {
      shareVideo.muted = true;
    }
  }

  // 編集したユーザー名を取得
  async setEditedUserNameToMember(peerId: string, userId: string): Promise<string> {
    const doc = await db.doc(`layers/${this.myUserId}/userLayers/${userId}`).get();
    if (!doc.exists) return "";

    const data = doc.data();
    if (!data || !data.displayName) return "";

    // 編集したユーザー名があれば、メンバーデータを更新
    const index = this.members.findIndex((x) => x.peerId === peerId);
    if (index !== -1) {
      this.members[index].userName = data.displayName;
    }

    return data.displayName as string;
  }

  // メンバーの音声検出を設定（発声中のアニメーション表示用）
  harkers: { peerId: string; harker: any }[] = [];
  setHarker(stream: any) {
    if (!stream || stream.getAudioTracks().length === 0) return;

    const index = this.harkers.findIndex((x) => x.peerId === stream.peerId);
    if (index !== -1) return;

    // 新しいメンバーの音声検出とイベントを設定
    const newHarker = { peerId: stream.peerId, harker: hark(stream, { setInterval: 1000 }) };
    newHarker.harker.on("speaking", () => {
      this.switchingSpeechState(stream.peerId, true);
    });
    newHarker.harker.on("stopped_speaking", () => {
      this.switchingSpeechState(stream.peerId, false);
    });
    this.harkers.push(newHarker);
  }

  // メンバーの発声状態を変更
  switchingSpeechState(peerId: any, speak: boolean) {
    const index = this.members.findIndex((x) => x.peerId === peerId);
    const member = this.members.find((x) => x.peerId === peerId);
    if (!member) return;
    // 変更
    member.isSpeak = speak;
    if (index !== -1) {
      this.members.splice(index, 1, member);
    }
  }

  async mounted() {
    // AudioContext が使えなければ利用不可
    if (!("AudioContext" in window)) {
      await util.showDialog({ title: "", dialogText: "お使いの端末ではご利用できません" }).then(() => window.close());
      return;
    }

    // loginId 取得
    this.loginId = localStorage.getItem("loginId") ?? "";

    // 親画面からのパラメータを取得（LocalStorage）
    const vcParam = this.getParam();
    if (!vcParam || !vcParam.userId || !vcParam.oppId || !this.loginId) {
      await util
        .showDialog({ title: "", dialogText: "接続に失敗しました。再度、入室を試みてください。" })
        .then(() => window.close());
      return;
    }
    this.param = vcParam as CallParam;
    this.myUserId = this.param.userId;

    // ローカルストレージのパラメータは削除しておく
    localStorage.removeItem("vcParam");

    // リロードされた時のイベント
    window.addEventListener("beforeunload", this.closeEvent);

    // window リサイズ対応
    this.setVideoAreaLayout();
    window.addEventListener("resize", this.setVideoAreaLayout);

    try {
      // PeerId を取得
      const peerId = await this.getMyPeerId();

      await this.openPeer(peerId);
    } catch (error) {
      await this.opnePeerHandleError(error);
    }
  }

  async opnePeerHandleError(error: any) {
    let title = "";
    let dialogText = "";
    if (error.type === "unavailable-id") {
      dialogText = "通話中のため入室出来ません。<br/>通話を終了し、改めてリンクをクリックしてください。";
    } else {
      // skyway 接続で何らかのエラーが発生
      title = "エラー";
      dialogText = "予期しないエラーが発生したため、通話を終了します。";
    }
    await util.showDialog({ title, dialogText });
    await this.callEnd();
  }

  async openPeer(peerId: string) {
    // 自分のデータを設定
    this.myData = {
      peerId: peerId,
      userId: this.param.userId,
      userName: this.param.userName,
      userIcon: this.param.userIcon,
      stream: null,
      muted: true,
      isDisplay: true,
      isConnect: true,
      isSpeak: false,
    };

    // apiKey を取得
    let _key = "";
    if (process.env.VUE_APP_SKYWAY_API_KEY !== undefined) {
      _key = process.env.VUE_APP_SKYWAY_API_KEY?.toString();
    }

    // skyway シグナリングサーバーと接続する
    this.peer = null;
    if (peerId === "") {
      this.peer = new Peer({ key: _key, debug: 1 });
    } else {
      this.peer = new Peer(peerId, { key: _key, debug: 1 });
    }

    // skyway 接続成功の処理
    this.peer.on("open", async () => {
      // peerId を新しく発行した場合は保存する
      this.myPeerId = peerId !== "" ? peerId : this.peer.id;
      if (peerId === "") {
        await this.saveMyPeerId();
      }
      // 入室準備
      if (this.param.callType === "videoRoom") {
        await this.settingsBeforeRoomJoin();
      }
    });

    // skyway エラー処理
    this.peer.on("error", async (e: any) => {
      await this.opnePeerHandleError(e);
    });

    // skyway peer.destroy（skywayへの接続を全て切断した） 時の処理
    this.peer.on("close", () => {});
  }

  created() {}

  beforeCreate() {}

  updated() {}

  beforeDestroy() {
    window.removeEventListener("beforeunload", this.closeEvent);
    window.removeEventListener("resize", this.setVideoAreaLayout);
    this.localStream = null;
    this.clearItems();
  }

  async clearItems() {
    this.stopMediaStream();
    this.myPeerId = "";

    this.isVoiceMute = true;
    this.isMediaSettings = false;
    this.isNoMic = true;

    this.meshRoom = null;
    this.myData = null;
    this.isActive = false;
    this.hasStream = false;

    this.shareId = "";
    this.memberMaxDispNum = 1;
  }

  stopMediaStream() {
    // stream の停止
    if (this.localStream) {
      this.stopStreamTracks(this.localStream);
      this.localStream = null;
    }

    if (this.myData) {
      this.myData.stream = null;
    }

    this.members.forEach((member) => {
      if (member.stream) {
        this.stopStreamTracks(member.stream);
      }
      member.stream = null;
    });
    this.members = [];

    this.setShareStream(null);

    this.streams.forEach((stream) => {
      if (stream) {
        this.stopStreamTracks(stream);
      }
    });
    this.streams = [];

    // video・audio 要素削除
    const allVideos = document.querySelectorAll("video");
    allVideos.forEach((video) => {
      video.srcObject = null;
      video.remove();
    });
    const allaudios = document.querySelectorAll("audio");
    allaudios.forEach((audio) => {
      audio.srcObject = null;
      audio.remove();
    });

    // hark の停止
    this.harkers.forEach((item: any) => item.harker.stop());
    this.harkers = [];

    // AudioContext を閉じる
    this.audioContexts.forEach((item: any) => {
      item.audioContext.close();
    });
    this.audioContexts = [];
  }

  stopStreamTracks(stream: MediaStream) {
    stream.getTracks().forEach((track) => track.stop());
  }

  // テロップ表示
  displayTelop(status: string, user: string) {
    this.telopMessage = "";
    if (status === "peerJoin") {
      this.telopMessage = `${user}が入室しました`;
    } else if (status === "peerLeave") {
      this.telopMessage = `${user}が退室しました`;
    } else {
      return;
    }
    this.isTelopDisplay = true;

    // テロップは数秒間、表示させる
    setTimeout(() => {
      this.isTelopDisplay = false;
    }, 5000);
  }

  // 通知メッセージ送信
  async sendNotification(message: string) {
    if (!message) return;
    const result = await sendMessage(this.myUserId.slice(-5), this.param.oppId.slice(-5), message, "notification");
    if (result) return;
  }

  // 退室メッセージ送信
  async exitMessage() {
    // 経過時間
    this.$refs.topbar.timerStop();
    const timeArr = this.$refs.topbar.time.split(":");
    let endTime = "";
    if (0 < parseInt(timeArr[0])) {
      endTime = parseInt(timeArr[0]) + "時間" + parseInt(timeArr[1]) + "分";
    } else {
      endTime = parseInt(timeArr[1]) + "分" + parseInt(timeArr[2]) + "秒";
    }

    if (!this.exitMessageSentFlag) {
      // 送信前にルームが閉じてしまう時があるので、promiseで処理
      const result = await this.sendPromise(endTime);
      if (result) this.exitMessageSentFlag = true;
    }
  }
  async sendPromise(endTime: string): Promise<boolean> {
    return new Promise((resolve) => {
      this.sendNotification(`退室しました（${endTime}）`).then(() => {
        resolve(true);
      });
    });
  }
}
</script>

<style lang="scss">
#container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: grid;
  // 内訳  [24px: 通話時間の高さ] [1fr: ビデオエリアの高さ] [56px: コントローラエリアの高さ]
  grid-template-rows: 24px 1fr 56px;
  grid-template-rows: calc(24px + env(safe-area-inset-top)) 1fr calc(56px + env(safe-area-inset-bottom));
}

#topbar {
  align-content: end;
  box-sizing: border-box;
}

#videoArea {
  // 内訳  [24px: 通話時間の高さ] [56px: コントローラエリアの高さ]
  height: calc(var(--vh, 1vh) * 100 - 24px - 56px);
  height: calc(var(--vh, 1vh) * 100 - 24px - 56px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  box-sizing: border-box;
  padding: 0 20px;
}

#videoArea.share {
  display: grid;
  grid-template-rows: 1fr 140px;
}

#videoArea.share.noVideo {
  grid-template-rows: 1fr 110px;
}

.my-video {
  position: absolute;
  right: 20px;
  bottom: 10px;
  box-sizing: border-box;
  margin: 0;
  width: 140px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #82dcb8;
  background-color: #fff;
  border-collapse: collapse;
}

.opp-video-container {
  width: 100%;
  height: 100%;
  text-align: center;
  display: grid;
  justify-items: center;
  align-items: center;
  border: solid 1px transparent;
}

.opp-video-container.share {
  overflow: auto;
  padding: 0 10px;
  scrollbar-width: thin;
  grid-row: 2;
  width: calc(100% - 140px);
  display: flex;
}

.opp-video-container.share::-webkit-scrollbar {
  width: 0.5rem;
}

.opp-video-container.share::-webkit-scrollbar-thumb {
  background-color: #dfdfdf;
  border-radius: 10px;
}

.share-video {
  max-width: 100%;
  max-height: 100%;
  min-height: 100%;
  grid-column: 1;
  margin: auto;
  border: solid 1px #82dcb8;
}

#shareVideoDiv {
  height: calc(var(--vh, 1vh) * 100 - 214px);
  height: calc(var(--vh, 1vh) * 100 - 214px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
  text-align: center;
  border: solid 1px transparent;
}

.noVideo #shareVideoDiv {
  // 内訳  [24px: 通話時間の高さ] [56px: コントローラエリアの高さ] [110px: メンバの高さ]
  height: calc(var(--vh, 1vh) * 100 - 24px - 56px - 110px);
  height: calc(var(--vh, 1vh) * 100 - 24px - 56px - 110px - env(safe-area-inset-top) - env(safe-area-inset-bottom));
}

.telop-icon {
  position: fixed;
  border-radius: 4px;
  left: 0px;
  width: auto;
  z-index: 10;
}

.telop-icon.mobile {
  position: absolute;
  width: 100%;
}

.telop-enter-active,
.telop-leave-active {
  transition: all 0.5s ease;
}

.telop-enter,
.telop-leave-to {
  opacity: 0;
}

.waiting-style {
  display: grid;
}

.waiting-text {
  font-size: 16px;
}

.cutting-text {
  font-size: 16px;
  position: absolute;
  text-align: center;
  top: calc((100% + 64px) / 2);
  width: 100%;
  z-index: 1;
}

.controller {
  border-radius: 4px;
  height: 46px;
  z-index: 1;
}
</style>
