<template>
  <div :class="className">通話時間：{{ time }}</div>
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";
import * as util from "@/common/util";

@Component
export default class TextDivider extends Vue {
  @Prop({ default: "" })
  className!: string;

  // 通話時間表示用
  time = "00:00:00";
  timeBegan: any = null;
  timerStarted: any = {};

  timerStart() {
    this.timerReset();
    this.timeBegan = new Date();
    this.timerStarted = setInterval(this.clockRunning, 1000);
  }

  timerReset() {
    this.timerStop();
    this.time = "00:00:00";
  }

  clockRunning() {
    const currentTime: any = new Date(),
      timeElapsed = new Date(currentTime - this.timeBegan),
      hour = timeElapsed.getUTCHours().toString(),
      min = timeElapsed.getUTCMinutes().toString(),
      sec = timeElapsed.getUTCSeconds().toString();

    this.time = util.zeroPrefix(hour, 2) + ":" + util.zeroPrefix(min, 2) + ":" + util.zeroPrefix(sec, 2);
  }

  timerStop() {
    if (this.timerStarted) clearInterval(this.timerStarted);
    this.timeBegan = null;
  }

  mounted() {}

  beforeDestroy() {
    this.timerStop();
  }
}
</script>
