<template>
  <div class="contentContainer">
    <div class="firstContainer mx-2 pl-1 py-2 text-left gct-normal--text">
      <slot name="key"></slot>
    </div>

    <div class="secondContainer mr-2 pr-1 py-2 text-left gct-normal--text">
      <slot name="setting"></slot>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue } from "vue-property-decorator";

@Component
// 設定画面などキーと設定を並べる、KeyValueでも良かったが何かとかぶりそうなので
export default class KeySetting extends Vue {}
</script>

<style lang="scss" scoped>
.contentContainer {
  display: flex;
  justify-content: space-between;
}

.firstContainer {
  display: inline-block;
  justify-self: flex-start;
  align-self: center;
  word-break: keep-all;
}

.secondContainer {
  display: inline-block;
  justify-self: flex-end;
  align-self: center;
  word-break: break-all;
}
</style>
