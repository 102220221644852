import { render, staticRenderFns } from "./MarginVerticalDivider.vue?vue&type=template&id=b0318746&scoped=true"
import script from "./MarginVerticalDivider.vue?vue&type=script&lang=ts"
export * from "./MarginVerticalDivider.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0318746",
  null
  
)

export default component.exports