<template>
  <hr
    class="gc-divider mx-1"
    :style="{ width: width + 'px', height: height + 'px', border: 'none' }"
    style="z-index:1;"
  />
</template>

<script lang="ts">
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MarginDivider extends Vue {
  @Prop({ default: "33" })
  height!: string;

  @Prop({ default: "1" })
  width!: string;
}
</script>

<style lang="scss" scoped></style>
